// React Import
import { Fragment, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Box,
  CardMedia,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListSubheader,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { drawerWidth } from 'config';

// assets
import { ApartmentOutlined, AppstoreOutlined, ArrowRightOutlined, BankOutlined, CarOutlined } from '@ant-design/icons';
import backgroundVector from 'assets/images/mega-menu/back.svg';
import imageChart from 'assets/images/mega-menu/chart.svg';
import AnimateButton from 'components/@extended/AnimateButton';
import AssignedProductCard from 'components/cards/statistics/AssignedProductCard';

// ==============================|| HEADER CONTENT - MEGA MENU SECTION ||============================== //

const MegaMenuSection = () => {
  const theme = useTheme();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const selectedProduct = pathArray[1];
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const trueProducts = [];
  if (user && user.productList) {
    for (var product in user.productList) {
      if (user.productList[product]) {
        trueProducts.push({ name: product });
      }
    }
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        color="primary"
        variant="contained"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<AppstoreOutlined style={{ fontSize: '1.1rem' }} />}
      >
        {selectedProduct === 'wms' ? 'WMS' : 'OMS'}
      </Button>
      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-180, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                minWidth: 300,
                width: {
                  md: `calc(100vw - 100px)`,
                  lg: `calc(100vw - ${drawerWidth + 100}px)`,
                  xl: `calc(100vw - ${drawerWidth + 140}px)`
                },
                maxWidth: 650
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <Grid container>
                    <Grid
                      item
                      md={6}
                      sx={{
                        background: `url(${backgroundVector}), linear-gradient(183.77deg, ${theme.palette.primary.main} 11.46%, ${theme.palette.primary[700]} 100.33%)`
                      }}
                    >
                      <Box sx={{ p: 4.5, pb: 3 }}>
                        <Stack sx={{ color: 'background.paper' }}>
                          <Typography variant="h2" sx={{ fontSize: '1.875rem', mb: 1 }}>
                            Explore Products
                          </Typography>
                          <Typography variant="h6">Try our pre made Products to check how it feels and suits as per your need.</Typography>
                          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mt: -1 }}>
                            <AnimateButton>
                              <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                  bgcolor: 'background.paper',
                                  color: 'text.primary',
                                  '&:hover': { bgcolor: 'background.paper', color: 'text.primary' }
                                }}
                                endIcon={<ArrowRightOutlined />}
                                component={Link}
                                to="/assigned-products"
                                target="_blank"
                              >
                                View All
                              </Button>
                            </AnimateButton>
                            <CardMedia component="img" src={imageChart} alt="Chart" sx={{ mr: -2.5, mb: -2.5, width: 124 }} />
                          </Stack>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          p: 4,
                          '& .MuiList-root': {
                            pb: 0
                          },
                          '& .MuiListSubheader-root': {
                            p: 0,
                            pb: 1.5
                          },
                          '& .MuiListItemButton-root': {
                            p: 0.5,
                            '&:hover': {
                              background: 'transparent',
                              '& .MuiTypography-root': {
                                color: 'primary.main'
                              }
                            }
                          }
                        }}
                      >
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <List
                              component="nav"
                              aria-labelledby="nested-list-user"
                              subheader={
                                <ListSubheader id="nested-list-user">
                                  <Typography variant="subtitle1" color="textPrimary">
                                    Products
                                  </Typography>
                                </ListSubheader>
                              }
                            >
                              <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                {trueProducts.length > 0 ? (
                                  trueProducts.map((value, index) => (
                                    <Fragment key={index}>
                                      <Grid item xs={4}>
                                        <ListItemButton
                                          disableRipple
                                          component={Link}
                                          target="_blank"
                                          to={
                                            value.name === 'OMS'
                                              ? '/oms/dashboard'
                                              : value.name === 'WMS'
                                              ? '/wms/dashboard'
                                              : value.name === 'TMS'
                                              ? '/tms/dashboard'
                                              : ''
                                          }
                                        >
                                          <Stack direction="column" textAlign="center">
                                            <AssignedProductCard
                                              iconPrimary={
                                                value.name === 'OMS'
                                                  ? ApartmentOutlined
                                                  : value.name === 'WMS'
                                                  ? BankOutlined
                                                  : value.name === 'TMS'
                                                  ? CarOutlined
                                                  : ''
                                              }
                                              color="#1890ff"
                                            />
                                            <Typography variant="caption" sx={{ fontWeight: '600' }}>
                                              {value.name}
                                            </Typography>
                                          </Stack>
                                        </ListItemButton>
                                      </Grid>
                                    </Fragment>
                                  ))
                                ) : (
                                  <>
                                    <Grid item xs={12}>
                                      <Typography variant="subtitle1" color="textPrimary">
                                        No Products Found
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </List>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default MegaMenuSection;
