import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import depService from './depService';

const initialState = {
  orders: [],
  order: null
};

// Fetch Item List
export const fetchDepsOrderList = createAsyncThunk(
  'deps/deps_order_list',
  async ({ pageNum, organizationId, spaceBookingId }, thunkAPI) => {
    try {
      return await depService.fetchDepsOrderList(pageNum, organizationId, spaceBookingId);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const markDamage = createAsyncThunk('deps/mark_damage', async (data, thunkAPI) => {
  try {
    return await depService.markDamage(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const markFaulty = createAsyncThunk('deps/mark_faulty', async (data, thunkAPI) => {
  try {
    return await depService.markFaulty(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const markGood = createAsyncThunk('deps/mark_good', async (data, thunkAPI) => {
  try {
    return await depService.markGood(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createRepairAndDisposeOrder = createAsyncThunk('deps/create_repair_and_dispose_order', async (data, thunkAPI) => {
  try {
    return await depService.createRepairAndDisposeOrder(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchDepsDetail = createAsyncThunk('deps/view_order', async (data, thunkAPI) => {
  try {
    return await depService.fetchDepsDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const expiryDepsClosed = createAsyncThunk('deps/expiry_dep_closed', async (data, thunkAPI) => {
  try {
    return await depService.expiryDepsClosed(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const depSlice = createSlice({
  name: 'dep',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepsOrderList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDepsOrderList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
      })
      .addCase(fetchDepsOrderList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(markDamage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markDamage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.order = action.payload.data;
      })
      .addCase(markDamage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(markFaulty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markFaulty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.order = action.payload.data;
      })
      .addCase(markFaulty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(markGood.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markGood.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.order = action.payload.data;
      })
      .addCase(markGood.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createRepairAndDisposeOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRepairAndDisposeOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.order = action.payload.data;
      })
      .addCase(createRepairAndDisposeOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDepsDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDepsDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(fetchDepsDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(expiryDepsClosed.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(expiryDepsClosed.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(expiryDepsClosed.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = depSlice.actions;
export default depSlice.reducer;
