import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Accordion, AccordionDetails, AccordionSummary, Box, List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';
import { ControlOutlined, FileProtectOutlined, HomeOutlined, QrcodeOutlined, RetweetOutlined, SettingOutlined } from '@ant-design/icons';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const getIcon = (title) => {
    switch (title) {
      case 'Home':
        return <HomeOutlined style={{ fontSize: '1.1rem' }} />;
      case 'Transactions':
        return <RetweetOutlined style={{ fontSize: '1.1rem' }} />;
      case 'Master':
        return <QrcodeOutlined style={{ fontSize: '1.1rem' }} />;
      case 'Reports':
        return <FileProtectOutlined style={{ fontSize: '1.1rem' }} />;
      case 'Setups':
        return <ControlOutlined style={{ fontSize: '1.1rem' }} />;
      case 'General':
        return <SettingOutlined style={{ fontSize: '1.1rem' }} />;
      default:
        return null;
    }
  };

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List>
      {item.title && drawerOpen ? (
        <Accordion sx={{ backgroundColor: 'transparent', border: '0' }}>
          <AccordionSummary
            aria-controls={`panel-${item.id}-content`}
            id={`panel-${item.id}-header`}
            sx={{
              backgroundColor: '#fff',
              width: '100%'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant="body1" sx={{ mr: getIcon(item.title.props.id) ? 1 : 0 }} color="#262626">
                {item.title}
              </Typography>
              {getIcon(item.title.props.id)}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>{navCollapse}</AccordionDetails>
        </Accordion>
      ) : (
        navCollapse
      )}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
