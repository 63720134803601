import { useState } from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { FilterContext } from '../../../../../contexts/FilterContext';

// material-ui
import { Box, Dialog, DialogTitle, Tooltip } from '@mui/material';

// project import
import IconButton from 'components/@extended/IconButton';
import OmsFilter from './OmsFilter';
import WmsFilter from './WmsFilter';
import ClientFilter from './ClientFilter';
import WarehousePartnerFilter from './WarehousePartnerFilter';

// assets
import { ControlOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

// ==============================|| Warehouse Customer Filter ||============================== //

function BootstrapDialogTitle(props) {
  const { onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[600]
          }}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const WarehouseCustomerFilter = (props) => {
  const { filterValue, setFilterValue } = useContext(FilterContext);
  const { finalFilterValue } = props;
  const location = useLocation();
  const [add, setAdd] = useState(false);
  const pathArray = location.pathname.split('/');
  const selectedProduct = pathArray[1];
  const user = JSON.parse(localStorage.getItem('user'));
  const filterSelected =
    user.organizationType === 2
      ? selectedProduct === 'oms'
        ? filterValue
          ? filterValue
          : JSON.parse(localStorage.getItem('omsFilteredData'))
        : selectedProduct === 'wms'
        ? filterValue
          ? filterValue
          : JSON.parse(localStorage.getItem('wmsFilteredData'))
        : null
      : user.organizationType === 3
      ? filterValue
        ? filterValue
        : JSON.parse(localStorage.getItem('clientFilteredData'))
      : null;
  const handleAdd = () => {
    setAdd(!add);
  };
  const onFilterChange = () => {
    let data;
    if (selectedProduct === 'oms' && user.organizationType === 2) {
      data = JSON.parse(localStorage.getItem('omsFilteredData'));
    } else if ((selectedProduct === 'wms' || selectedProduct === 'tms') && user.organizationType === 2) {
      data = JSON.parse(localStorage.getItem('wmsFilteredData'));
    } else if (user.organizationType === 3 && user.organizationSubType === 3) {
      data = JSON.parse(localStorage.getItem('clientFilteredData'));
    } else if (user.organizationType === 3 && user.organizationSubType === 4) {
      data = JSON.parse(localStorage.getItem('whPartnerFilteredData'));
    }
    finalFilterValue(data);
    setFilterValue(data);
  };

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <Tooltip title={user.organizationType === 3 ? 'Select Warehouse' : 'Select Client/Warehouse'}>
          <IconButton
            sx={{
              color: 'text.primary',
              bgcolor:
                user.organizationType === 3
                  ? filterSelected?.warehouse.length > 0
                    ? '#D3F1DF'
                    : '#FFCFCF'
                  : filterSelected?.client
                  ? '#D3F1DF'
                  : '#FFCFCF'
            }}
            color={
              user.organizationType === 3
                ? filterSelected?.warehouse.length > 0
                  ? 'success'
                  : 'error'
                : filterSelected?.client
                ? 'success'
                : 'error'
            }
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              handleAdd();
            }}
            aria-label="settings toggler"
          >
            <ControlOutlined style={{ fontSize: '1.1rem' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog maxWidth="md" fullWidth onClose={handleAdd} open={add} sx={{ '& .MuiDialog-paper': { p: 3 } }}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleAdd}></BootstrapDialogTitle>
        {add && selectedProduct === 'oms' && user.organizationType === 2 && (
          <OmsFilter onFilterChange={onFilterChange} onCancel={handleAdd} />
        )}
        {add && (selectedProduct === 'wms' || selectedProduct === 'tms') && user.organizationType === 2 && (
          <WmsFilter onFilterChange={onFilterChange} onCancel={handleAdd} />
        )}
        {add &&
          (selectedProduct === 'oms' || selectedProduct === 'wms' || selectedProduct === 'tms') &&
          user.organizationType === 3 &&
          user.organizationSubType === 3 && <ClientFilter onFilterChange={onFilterChange} onCancel={handleAdd} />}
        {add &&
          (selectedProduct === 'oms' || selectedProduct === 'wms' || selectedProduct === 'tms') &&
          user.organizationType === 3 &&
          user.organizationSubType === 4 && <WarehousePartnerFilter onFilterChange={onFilterChange} onCancel={handleAdd} />}
      </Dialog>
    </>
  );
};

export default WarehouseCustomerFilter;
WarehouseCustomerFilter.propTypes = {
  finalFilterValue: PropTypes.func
};
