import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import logomain from '../../assets/images/logo_main.png';
import logosmall from '../../assets/images/logo_small.png';
import config from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ isIcon, sx, to }) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    {isIcon ? (
      <img src={logosmall} srcSet={logosmall} width="30" height="40" alt="logo" loading="lazy" />
    ) : (
      <img src={logomain} srcSet={logomain} width="130" height="45" alt="logo" loading="lazy" />
    )}
  </ButtonBase>
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
